import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { v4 as uuId } from "uuid";
import { FormContext } from "../contexts/FormContext";
import { getFieldDefaultValue } from "../components/fieldsDisplayComponent/getDefaultValue";
import { executeAppForTempStorage, getLastNodeData } from "../../../services/API";

const DynamicDependentDropdown = ({
  field,
  onChange,
  value = [],
  readonly,
  required,
  elementData,
}) => {
  const { formData, appSessionId, isEdit, uidKeyName, setUidKeyName } =
    useContext(FormContext);
  const [selectedOption, setSelectedOption] = useState([]);
  const [options, setOptions] = useState([]);
  const [isDependentDataLoading, setIsDependentDataLoading] = useState(false);
  const [isApiError, setIsApiError] = useState("");
  const [isDisable, setIsDisable] = useState(readonly);

  const {
    depends_on,
    logic_to_call,
    dependentReference,
    key_to_send,
    key_to_show,
  } = field;

  const isChild = depends_on?.value;

  let payloadForTempStorage = {
    data: {},
    reference_name: dependentReference,
    app_session_id: appSessionId,
  };

  //for child
  useEffect(() => {
    let timeoutId;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      if (formData?.[isChild] && formData?.[isChild]?.length) {
        //if (uidKeyName?.includes(isChild) || isEdit) {
          setIsDependentDataLoading(true);
          payloadForTempStorage.data = {
            [isChild]: formData?.[isChild],
          };
          executeAppForTempStorage(payloadForTempStorage)
            .then((res) => {
              if (res) {
                let logicList = [];
                let newOpts = [];
                getLastNodeData(appSessionId, logic_to_call)
                  .then((res) => {
                    setIsDependentDataLoading(false);
                    if (Array.isArray(res?.data?.value?.data)) {
                      logicList = res?.data?.value?.data;
                    } else {
                      logicList = res?.data?.value?.data?.data;
                    }
                    newOpts = logicList?.map((item) => {
                      if (item?.[key_to_show]) {
                        let obj = {
                          value: item[key_to_send],
                          label: item[key_to_show],
                        };
                        return obj;
                      }
                    });
                    newOpts = newOpts?.filter((opts) => opts?.value);
                    setOptions(newOpts);
                    setSelectedOption(valueToOptionValue(value, newOpts));
                    if (Array.isArray(value))
                      onChange(value || getFieldDefaultValue(field));
                    else onChange([value] || getFieldDefaultValue(field));
                  })
                  .catch((err) => {
                    setIsApiError(err?.message);
                    setIsDependentDataLoading(false);
                  });
              }
            })
            .catch((err) => {
              setIsApiError(err?.message);
              setIsDependentDataLoading(false);
            });
      } else {
        setSelectedOption([]);
        setOptions([]);
        onChange(getFieldDefaultValue(field) || "");
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [JSON.stringify(formData?.[depends_on?.value])]);

  useEffect(() => {
    if (elementData?.visibleWhenField) {
      if (
        formData?.[elementData?.visibleWhenField] ===
        elementData?.visibleWhenValue
      ) {
        setIsDisable(false);
      } else {
        setIsDisable(true);
      }
    }
  }, [formData?.[elementData?.visibleWhenField], readonly]);

  const valueToOptionValue = (selectedValue, optionsList) => {
    let value = [];
    value = optionsList.filter((option) => {
      if (
        selectedValue?.includes(option.value) ||
        selectedValue === option.value
      ) {
        return option;
      }
    });

    return value;
  };

  const handleSelect = (option) => {
    setUidKeyName(uuId() + field.name);

    if (option) {
      if (Array.isArray(option)) {
        setSelectedOption(option);
        onChange(option?.map((option) => option.value));
      } else {
        setSelectedOption(option);
        onChange([option]?.map((option) => option.value));
      }
    } else {
      setSelectedOption([]);
      onChange(getFieldDefaultValue(field) || []);
    }
  };

  const noOptionMessage = () => {
    if (isChild && !formData?.[isChild]?.length) {
      return (
        <span className="text-danger">
          Select {depends_on?.label} to see options
        </span>
      );
    } else if (isApiError) {
      return <span className="text-danger">{isApiError}</span>;
    } else {
      return <span>No Options</span>;
    }
  };

  return (
    <Select
      classNamePrefix={"react-select"}
      isLoading={isDependentDataLoading}
      isClearable
      required={required}
      menuPosition="fixed"
      options={options}
      isDisabled={isDisable}
      value={isDependentDataLoading ? [] : selectedOption}
      onChange={handleSelect}
      noOptionsMessage={noOptionMessage}
      className="dynamicDropdown fieldMinimumWidth fieldMaximumWidth mx-1 mb-2 flex-grow-1"
      classNames={{
        control: () => "searchableDropdownControl",
        menuPortal: () => "searchableDropdownMenuPortal",
        singleValue: () => "searchableDropdownValue",
        multiValue: () => "searchableDropdownValue",
        menuList: () => "searchableDropdownMenuList",
        indicatorsContainer: () => "mx-1",
        indicatorSeparator: () => "mx-1",
        valueContainer: () => "my-1",
      }}
    />
  );
};

export default DynamicDependentDropdown;
