import React, { Component } from "react";
import { toast } from "react-toastify";
import Loader from "../../dataset/Loader/Loader";
import {
  deleteScheduleRecord,
  fetchSchedules,
  runSchedule,
} from "../../../services/appService";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import "./schedule.scss";

export class Schedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: JSON.parse(localStorage.getItem("user-info")),
      isWrite: false,
      scheduleData: [],
      loading: true,
      errorMsg: "",
      headers: [
        "Sr No.",
        "Schedule Name",
        "Schedule",
        "Activation Status",
        "Current Status",
        "Last Status",
        "Last Error",
        "Last Duration",
        "App",
        "Logic",
        "Parameter (JSON)",
        "Next Run Datetime",
        "No. Of Retries",
        "Max Runtime",
        "Action",
        "Run Now",
      ],
      runningState: false,
      runIndex: -1,
      totalRecordCount: 0,
    };
  }

  componentDidMount() {
    this.getPrivilages();
    this.getScheduleListData();
    this.mount = true;
  }

  getPrivilages = () => {
    if (this.state.userInfo.privileges) {
      const { app_previleges, data_previleges, standard_privileges } =
        this.state.userInfo.privileges;
      standard_privileges.forEach((data, index) => {
        if (data["Schedules"]) {
          this.setState({ isWrite: data["Schedules"]?.write });
        }
      });
    } else {
      this.setState({ isWrite: true });
    }
  };

  runSingleSchedule(scheduleId, index) {
    confirmAlert({
      message: "Please confirm, you want to run this schedule immediately. The job will run in the background?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ runningState: true, runIndex: index });
            runSchedule(scheduleId)
              .then((response) => {
                this.setState({ runningState: false });
                toast.success(response.data || response.message);
              })
              .catch((err) => {
                this.setState({ runningState: false });
                toast.error(err.message || err.data);
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  deleteSchedule(obj_id) {
    confirmAlert({
      message: "Are you sure you want to delete this schedule permanently?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let objData = {};
            objData = {
              data: [obj_id],
            };
            deleteScheduleRecord(objData)
              .then((response) => {
                toast.success(response.message);
                this.getScheduleListData();
              })
              .catch((err) => {
                this.setState({ loading: false, errorMsg: true }, () => {
                  toast.error(err.message);
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  getScheduleListData() {
    this.setState({ loading: true });
    fetchSchedules()
      .then((response) => {
        if (this.mount) {
          if (response.success === true) {
            this.setState({
              scheduleData: response.data,
              totalRecordCount: response.total_count,
              loading: false,
            });
          } else {
            this.setState({ errorMsg: true, loading: false }, () => {
              toast.error(response.message);
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false, errorMsg: true }, () => {
          toast.error(err.message);
        });
      });
  }

  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    const setHeaderData = this.state.isWrite
      ? this.state.headers.map((header, index) => (
          <th className="text-nowrap" key={index}>
            {header}
          </th>
        ))
      : this.state.headers
          .filter((header) => header !== "Action")
          .map((header, index) => (
            <th className="text-nowrap" key={index}>
              {header}
            </th>
          ));

    const setTableData = this.state.scheduleData.map((tableData, index) => (
      <tr key={index}>
        <td className="max_width">{index + 1}</td>
        <td className="max_width" title={tableData.schedule_name}>{tableData.schedule_name}</td>
        <td className="max_width">{tableData.cron_string}</td>
        <td className="max_width">{tableData.activation_status ? "Active" : "Inactive"}</td>
        <td className="max_width">{tableData.status}&nbsp;&nbsp;&nbsp;
        {
          tableData.status === "Running" ? (
            <FontAwesomeIcon icon="fa-solid fa-spinner" spinPulse />
          ) : null
        }
        </td>
        <td className="max_width" title={tableData.last_error}>{tableData.last_status}&nbsp;&nbsp;&nbsp;
        {
          tableData.last_status === "ERROR" ? (
            <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" style={{color: "#e64561",}} />
          ) : null
        }</td>
        <td className="max_width" title={tableData.last_error}>{tableData.last_error}</td>
        <td className="max_width">{tableData.duration}</td>
        <td className="max_width">{tableData.app_name}</td>
        <td className="max_width" title={tableData.logic_name}>{tableData.logic_name}</td>
        <td className="max_width" title={tableData.parameters}>{tableData.parameters}</td>
        <td className="max_width">{tableData.next_run_time}</td>
        <td className="max_width">{tableData.no_of_retries}</td>
        <td className="max_width">{tableData.max_run_time}</td>
        {this.state.isWrite ? (
          <td className="text-nowrap">
            <div className="d-flex text-center m-0 justify-content-center">
              <div className="action-icons text-center">
                <Link to={`update-schedule/${tableData._id.$oid}`}>
                  <FontAwesomeIcon
                    icon={"fa fa-pen-to-square"}
                    className="me-2"
                  />
                </Link>&nbsp;&nbsp;
                <FontAwesomeIcon
                  role="button"
                  icon={"fa fa-trash"}
                  className="me-2"
                  onClick={() =>
                    this.deleteSchedule(tableData._id.$oid)
                  }
                />
              </div>
            </div>
          </td>
        ) : null}
        <td>
          {this.state.runningState && this.state.runIndex === index ? (
            <div className="spinner-border spinner-border-sm" />
          ) : (
            <div className="action-icons text-center">
              <FontAwesomeIcon
                role="button"
                icon={"fa fa-play"}
                className="me-2 fa-xl"
                title="Run scheduled job in background"
                onClick={() =>
                  this.runSingleSchedule(tableData._id.$oid, index)
                }
              />
            </div>
          )}
        </td>
      </tr>
    ));

    return (
      <div>
        <div className="d-help py-2 flex-wrap">
          <h5>Schedules</h5>
          <div className="d-flex">
            <FontAwesomeIcon
                role="button"
                icon={"fa fa-solid fa-rotate-right"}
                className="me-2 fa-xl mt-2"
                title="Reload Schedules"
                onClick={() =>
                  this.getScheduleListData()
                }
              />&nbsp;&nbsp;
            <div className="input-group input-group-sm mb-0">
              <span className="input-group-text" id="basic-addon1">
                <FontAwesomeIcon icon={"fa fa-search"} />
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Search Schedule"
              />
            </div>
            {this.state.isWrite ? (
              <Link to={"/create-schedule"}>
                <button
                  type="button"
                  className="btn btn-success btn-md m-0 text-nowrap "
                >&nbsp;&nbsp;<FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                  &nbsp;&nbsp;Create Schedule&nbsp;&nbsp;
                </button>
              </Link>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <div className="table-responsive table-hover">
              {this.state.loading ? (
                <Loader />
              ) : (
                <table className="table  table-bordered mb-0">
                  <thead>
                    <tr className="head-row">{setHeaderData}</tr>
                  </thead>
                  <tbody>
                    {this.state.totalRecordCount !== 0 ? (
                      setTableData
                    ) : (
                      <>
                        <tr className="">
                          <td
                            colSpan={this.state.headers.length}
                            className="no_table_records"
                          >
                            No records found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Schedule;
